/** @jsx createElement */
import { createElement } from "nni-hcp-common/jsx";
import request from "nni-hcp-common/request";
import { Modal, validateEmail } from "./_modal";
const SELECTOR_FORGOT_PASSWORD_MODAL = "#cope-core-hcp-account-buttons__forgot-password-modal .cope-core-hcp-account-buttons__modal--content";
const SELECTOR_FORGOT_PASSWORD_SUBMIT_BUTTON = ".cope-core-hcp-account-buttons__forgot-password-modal--submit";
const SELECTOR_FORGOT_PASSWORD_BUTTON = ".cope-core-hcp-account-buttons__signin-modal--forgot-password";
const SELECTOR_FORGOT_PASSWORD_EMAIL = "#cope-core-hcp-account-buttons__forgot-password-modal--email";
const SELECTOR_FORGOT_PASSWORD_CONTENT = "#cope-core-hcp-account-buttons__forgot-password-modal .cope-core-hcp-account-buttons__modal--content";
export default class ForgotPasswordModal extends Modal {
    constructor() {
        super(document.querySelector(SELECTOR_FORGOT_PASSWORD_BUTTON), document.querySelector(SELECTOR_FORGOT_PASSWORD_MODAL));
    }
    onOpen() {
        if (this.successMessage) {
            this.successMessage.remove();
        }
        if (this.content) {
            this.content.childNodes.forEach((node) => {
                if (node instanceof HTMLElement) {
                    node.style.display = "";
                }
            });
        }
        super.onOpen();
    }
    resolveElements(root) {
        this.emailElement = root.querySelector(SELECTOR_FORGOT_PASSWORD_EMAIL);
        this.content = document.querySelector(SELECTOR_FORGOT_PASSWORD_CONTENT);
    }
    getSubmitButton(root) {
        return root.querySelector(SELECTOR_FORGOT_PASSWORD_SUBMIT_BUTTON);
    }
    getFormFields() {
        return [
            {
                element: this.emailElement,
                validate: () => this.emailElement &&
                    this.emailElement instanceof HTMLInputElement &&
                    this.emailElement.value &&
                    typeof this.emailElement.value === "string" &&
                    validateEmail(this.emailElement.value),
            },
        ];
    }
    handleErrors() {
        this.displayError("We're sorry. An error has occured. Please try again.");
    }
    submit() {
        return request
            .post("api/account/v2/passwords/forgot", {
            body: JSON.stringify({ email: this.emailElement.value }),
            headers: { "Content-Type": "application/json" },
        })
            .then((response) => {
            const responseOK = response.status === 200 || response.ok;
            if (!responseOK) {
                throw response;
            }
            this.showSuccessMessage();
        });
    }
    showSuccessMessage() {
        if (!this.content) {
            return;
        }
        this.successMessage = (createElement("div", null,
            createElement("h2", null, "Email Sent"),
            createElement("p", null,
                "We sent an email to ",
                this.emailElement.value,
                " with a link to reset your password. If you don\u2019t receive it, try checking your spam folder.")));
        this.content.childNodes.forEach((node) => {
            if (node instanceof HTMLElement) {
                node.style.display = "none";
            }
        });
        this.content.appendChild(this.successMessage);
    }
}
