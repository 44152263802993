import Account from "nni-hcp-common/account";
import Events from "nni-hcp-common/event";
import { Modal, validateEmail } from "./_modal";
const SELECTOR_SIGNIN_BUTTON = ".cope-core-hcp-account-buttons__sign-in";
const SELECTOR_SIGNIN_SUBMIT_BUTTON = ".cope-core-hcp-account-buttons__signin-modal--submit";
const SELECTOR_SIGNIN_MODAL = "#cope-core-hcp-account-buttons__signin-modal .cope-core-hcp-account-buttons__modal--content";
const SELECTOR_SIGNIN_EMAIL = "#cope-core-hcp-account-buttons__signin-modal--email";
const SELECTOR_SIGNIN_PASSWORD = "#cope-core-hcp-account-buttons__signin-modal--password";
const SELECTOR_CLOSE_BUTTON = "#cope-core-hcp-account-buttons__signin-modal .cope-core-hcp-account-buttons__modal--close";
export default class SigninModal extends Modal {
    constructor() {
        super(document.querySelector(SELECTOR_SIGNIN_BUTTON), document.querySelector(SELECTOR_SIGNIN_MODAL));
    }
    close() {
        const closeButton = document.querySelector(SELECTOR_CLOSE_BUTTON);
        if (closeButton) {
            closeButton.click();
        }
    }
    resolveElements(root) {
        this.emailElement = root.querySelector(SELECTOR_SIGNIN_EMAIL);
        this.passwordElement = root.querySelector(SELECTOR_SIGNIN_PASSWORD);
    }
    getSubmitButton(root) {
        return root.querySelector(SELECTOR_SIGNIN_SUBMIT_BUTTON);
    }
    getFormFields() {
        return [
            {
                element: this.emailElement,
                validate: () => this.emailElement &&
                    this.emailElement instanceof HTMLInputElement &&
                    this.emailElement.value &&
                    typeof this.emailElement.value === "string" &&
                    validateEmail(this.emailElement.value),
            },
            {
                element: this.passwordElement,
                validate: () => this.passwordElement &&
                    this.passwordElement instanceof HTMLInputElement &&
                    this.passwordElement.value &&
                    typeof this.passwordElement.value === "string" &&
                    this.passwordElement.value.trim().length > 0,
            },
        ];
    }
    handleErrors(response) {
        const message = response.errors &&
            (response.errors.some((x) => x.field === "email") ||
                response.errors.some((x) => x.field === "password"))
            ? "Your email or password is incorrect. Please try again."
            : "We're sorry. An error has occured. Please try again.";
        this.displayError(message);
    }
    submit() {
        Events.trigger(Events.UserLoginStart);
        return Account.login(this.emailElement.value, this.passwordElement.value).then(() => Events.trigger(SigninModal.EVENT_SIGNED_IN));
    }
}
SigninModal.EVENT_SIGNED_IN = "signed-in";
