const SELECTOR_GENERAL_ERROR = ".cope-core-hcp-account-buttons__modal--error";
const CLASS_HAS_ERROR = "has-error";
const CLASS_LOADING = "loading";
export function validateEmail(email) {
    const match = email.match(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/);
    return match && match.length !== 0;
}
export class Modal {
    constructor(trigger, rootElement) {
        if (!trigger || !rootElement) {
            return;
        }
        this.trigger = trigger;
        this.resolveElements(rootElement);
        this.formElement = rootElement.querySelector("form");
        this.formFields = this.getFormFields();
        this.submitButton = this.getSubmitButton(rootElement);
        this.generalErrorElement = rootElement.querySelector(SELECTOR_GENERAL_ERROR);
        this.setupEvents();
    }
    onOpen() {
        this.hideErrors();
        this.clearFields();
        if (this.formFields.length > 0) {
            this.formFields[0].element.focus();
        }
    }
    clearFields() {
        this.formFields.forEach((field) => {
            if (field.element instanceof HTMLInputElement) {
                field.element.value = "";
            }
        });
    }
    hideErrors() {
        this.generalErrorElement.innerHTML = "";
        this.formFields.forEach((field) => field.element.parentElement.classList.remove(CLASS_HAS_ERROR));
    }
    setupEvents() {
        this.trigger.addEventListener("click", (e) => {
            e.preventDefault();
            this.onOpen();
        });
        this.formElement.addEventListener("submit", (e) => {
            e.preventDefault();
            if (!this.validate()) {
                return;
            }
            this.setLoading(true);
            this.submit()
                .catch(this.handleErrors.bind(this))
                .finally(() => this.setLoading(false));
        });
    }
    validate() {
        const errorFields = this.formFields.filter((field) => !field.validate());
        if (errorFields.length === 0) {
            return true;
        }
        errorFields.forEach((field) => {
            if (field.element) {
                field.element.parentElement.classList.add(CLASS_HAS_ERROR);
            }
        });
    }
    displayError(error) {
        if (this.generalErrorElement) {
            this.hideErrors();
            this.generalErrorElement.innerHTML = error;
        }
    }
    setLoading(loading) {
        if (!this.submitButton) {
            return;
        }
        if (loading) {
            this.submitButton.disabled = true;
            this.submitButton.classList.add(CLASS_LOADING);
        }
        else {
            this.submitButton.disabled = false;
            this.submitButton.classList.remove(CLASS_LOADING);
        }
    }
}
