/** @jsx createElement */
import { createElement } from "nni-hcp-common/jsx";
/* eslint @typescript-eslint/no-var-requires: 0 */
import Account from "nni-hcp-common/account";
import Events from "nni-hcp-common/event";
import SigninModal from "./_signin-modal";
import ForgotPasswordModal from "./_forgot-password-modal";
window.NovoMedLink = window.NovoMedLink || {};
window.NovoMedLink.account = window.NovoMedLink.account || Account;
(() => {
    const SELECTOR_WRAPPER = ".cope-core-hcp-account-buttons";
    const SELECTOR_BUTTONS = ".cope-core-hcp-account-buttons__buttons";
    const SELECTOR_GREETING = ".cope-core-hcp-account-buttons__greeting";
    const SELECTOR_CREATE_ACCOUNT_BUTTONS = ".cope-core-hcp-account-buttons__create-account";
    const SELECTOR_OPEN_MODAL_BUTTON = "[data-modal-trigger-open]";
    const CLASS_AUTHENTICATED = "cope-core-hcp-login-status-authenticated";
    const DATA_SOURCE = "source";
    const DATA_RETURN_URL = "returnUrl";
    const DATA_UNAUTHENTICATED_REDIRECT = "redirect";
    const DATA_AUTHORING_MODE = "authoringMode";
    function setupEvents(wrapper) {
        const source = wrapper.dataset[DATA_SOURCE];
        if (!wrapper || !source) {
            return;
        }
        const openModalButtons = wrapper.querySelectorAll(SELECTOR_OPEN_MODAL_BUTTON);
        openModalButtons.forEach((button) => {
            button.addEventListener("click", (e) => e.preventDefault());
        });
        const createAccountButtons = wrapper.querySelectorAll(SELECTOR_CREATE_ACCOUNT_BUTTONS);
        if (source && createAccountButtons) {
            createAccountButtons.forEach((button) => {
                const returnUrl = button.dataset[DATA_RETURN_URL];
                if (returnUrl) {
                    button.addEventListener("click", function (e) {
                        e.preventDefault();
                        Account.register(source, returnUrl);
                    });
                }
            });
        }
    }
    function handleAuthenticatedUser() {
        Account.getUser().then((user) => {
            if (user) {
                document.body.classList.add(CLASS_AUTHENTICATED);
                displayGreeting(user);
            }
        });
    }
    function onLogout(e) {
        e.preventDefault();
        document.body.classList.remove(CLASS_AUTHENTICATED);
        Account.logout();
        const buttonGroups = document.querySelectorAll(SELECTOR_BUTTONS);
        buttonGroups.forEach((buttons) => {
            buttons.style.display = "";
        });
        const greetings = document.querySelectorAll(SELECTOR_GREETING);
        greetings.forEach((greeting) => {
            greeting.innerHTML = "";
        });
        redirectToUnauthenticatedRedirect();
    }
    function displayGreeting(user) {
        const buttonGroups = document.querySelectorAll(SELECTOR_BUTTONS);
        buttonGroups.forEach((buttons) => {
            buttons.style.display = "none";
        });
        const greetings = document.querySelectorAll(SELECTOR_GREETING);
        greetings.forEach((greeting) => {
            greeting.appendChild(createElement("span", null, `${getTimedGreeting()} ${user.title || user.firstName} ${user.lastName}`));
            greeting.appendChild(createElement("a", { href: "#", onClick: onLogout }, "Sign Out"));
        });
    }
    function getTimedGreeting() {
        const curHr = new Date().getHours();
        if (curHr < 12) {
            return "Good morning";
        }
        else if (curHr < 18) {
            return "Good afternoon";
        }
        return "Good evening";
    }
    function isCurrentPage(url) {
        return (url === window.location.pathname ||
            url === window.location.origin + window.location.pathname);
    }
    Events.on(Events.PageContentLoaded, () => {
        Events.on(Events.UserLoginComplete, handleAuthenticatedUser);
        document
            .querySelectorAll(SELECTOR_WRAPPER)
            .forEach((wrapper) => setupEvents(wrapper));
        handleAuthenticatedUser();
        const signinModal = new SigninModal();
        const forgotPasswordModal = new ForgotPasswordModal();
        Events.on(SigninModal.EVENT_SIGNED_IN, () => {
            signinModal.close();
        });
    });
    Events.on(Events.PageLoaded, () => {
        redirectToUnauthenticatedRedirect();
    });
    function redirectToUnauthenticatedRedirect() {
        const roots = document.querySelectorAll(SELECTOR_WRAPPER);
        const authoringMode = [].slice.call(roots).find((root) => root && root.dataset[DATA_AUTHORING_MODE]) !== undefined;
        const rawRedirectUrl = [].slice.call(roots)
            .map((root) => root && root.dataset[DATA_UNAUTHENTICATED_REDIRECT])
            .find((x) => x && x.trim() !== "");
        if (!authoringMode && rawRedirectUrl) {
            const redirect = rawRedirectUrl.startsWith("/") && !rawRedirectUrl.includes(".html")
                ? rawRedirectUrl + ".html"
                : rawRedirectUrl;
            if (!isCurrentPage(redirect)) {
                Account.getUser().then((user) => {
                    if (!user) {
                        window.location.href = redirect;
                    }
                });
            }
        }
    }
})();
